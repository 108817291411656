import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from '../../components/joblayout';
import SEO from '../../components/seo';
import {
  formatTime, getDescription,
} from '../../utils/util';
import { getJobList, getRecruitDetailByFSApi } from '../../utils/api';
import * as styles from './FSRecruitmentDetail.module.scss';
import { saveJobAdCategory } from '../../state/app';
import { getFSApi } from '../../utils/feishu';

class RecruitmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobItem: {},
    };
  }

  componentDidMount() {
    // console.log('recruitmentDetail', this.props);
    const { location } = this.props;
    const jobId = new URLSearchParams(location.search).get('jobId');
    const websiteId = new URLSearchParams(location.search).get('websiteId');
    this.setState({ websiteId });

    // let params = this.getParams(location.search);
    const jobadCategory = websiteId == '7005456241946331399' ? '3' : '1';
    this.props.saveJobAdCategory(jobadCategory);

    getFSApi(getRecruitDetailByFSApi, { websiteId, job_post_id: jobId }).then(
      (res) => {
        // console.log('response', res);
        this.setState({
          jobItem: res.job_post,
        }, () => {
          console.log(this.state.jobItem);
        });
      },
    ).catch((err) => {
      console.error(err);
    });
    // this.getJobAdCategory(location);
    // this.getJobItem(location).then(res => {
    // this.setState({
    //   jobItem: this.state.tempJobDetail
    // })
    // });
  }

  async getJobItem(location) {
    return new Promise(async (resolve, reject) => {
      const { state, search } = location;
      const jobItem = state && state.jobItem;
      if (jobItem) {
        resolve(jobItem);
        return;
      }

      if (!search) {
        return;
      }
      const params = this.getParams(search);
      const { Id } = params;
      delete params.Id;

      return await getJobList(params).then(async (res) => {
        const jobItem = this.findJobItem(res.data, Id);
        if (jobItem) {
          resolve(jobItem);
        } else {
          // todo 显示loading
          let pageNum = 1;
          const pageSize = 100;
          while (true) {
            let resData;
            await getJobList({
              pageNum,
              pageSize,
              jobadCategory,
            }).then((res) => {
              resData = res.data;
              const jobItem = this.findJobItem(resData, Id);
              if (jobItem) {
                resolve(jobItem);
              }
              pageNum++;
            });
            if (resData.length < pageSize || jobItem) {
              this.setState({
                dataLoadingEnd: true,
              });
              break;
            }
          }
          // resolve(jobItem)
        }
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  getJobAdCategory(location) {
    const { state, search } = location;
    const params = this.getParams(search);
    this.props.saveJobAdCategory(params.jobadCategory);
  }

  findJobItem(data, Id) {
    const jobItem = data.find((item) => item.Id == Id);
    return jobItem;
  }

  getParams(search) {
    search = decodeURIComponent(search);
    const searchParams = new URLSearchParams(search);
    const jobParamsStr = searchParams.get('jobParams');
    const paramsContent = jobParamsStr.split('^');
    const params = {};
    paramsContent.forEach((item) => {
      if (item) {
        const param = item.split('=');
        params[param[0]] = param[1];
      }
    });
    return params;
  }

  // 申请职位
  applyJob(lang) {
    const { jobItem, websiteId } = this.state;
    const jobId = jobItem && jobItem.id;
    if (lang === 'zh') {
      if (websiteId === '6962795203808168199') { // 社招
        window.open(`https://anker-in.jobs.feishu.cn/index/resume/${jobId}/apply`);
      } else if (websiteId === '6962795203808217351') { // 校招
        window.open(`https://anker-in.jobs.feishu.cn/campushirecn/resume/${jobId}/apply`);
      } else if (websiteId === '7005456241946331399') { // 实习生招聘
        window.open(`https://anker-in.jobs.feishu.cn/internhirecn/resume/${jobId}/apply`);
      }
    } else {
      // window.open(`https://anker-in.jobs.feishu.cn/experiencedhireen/resume/${feishuJobId}/apply`);
      if (websiteId === '7069578816822282503') { // 社招
        window.open(`https://anker-in.jobs.feishu.cn/experiencedhireen/resume/${jobId}/apply`);
      } else if (websiteId === '7268177039772633400') { // 校招
        window.open(`https://anker-in.jobs.feishu.cn/189381/resume/${jobId}/apply`);
      } else {
        window.open(`https://anker-in.jobs.feishu.cn/experiencedhireen/resume/${jobId}/apply`);
      }
    }
  }

  render() {
    const { data, pageContext } = this.props;
    const copywriting = data.contentfulCopywriting;
    const lang = pageContext.language;
    const { websiteId, jobItem } = this.state;
    const copyright = copywriting.jobDetails;
    const NoDataPlaceholder = () => {
      if (this.state.dataLoadingEnd) {
        return (
          <div dangerouslySetInnerHTML={{ __html: copyright.no_data_description }}></div >
        );
      }
      return null;
    };
    // console.log({ jobItem });
    jobItem.HeadCount = jobItem.headcount > 0
      ? jobItem.headcount
      : copywriting.common?.multiple_description;
    jobItem.CategoryDescription = copywriting.config.websites
      ?.find((website) => website.id === websiteId)?.description || '';

    const minSalary = jobItem.min_salary || copyright.uncertain_salary_description;
    const maxSalary = jobItem.max_salary || copyright.uncertain_salary_description;
    jobItem.Salary = `${minSalary}～${maxSalary}`;
    if (!jobItem.min_salary && !jobItem.max_salary) {
      jobItem.Salary = copyright.uncertain_salary_description;
    }
    jobItem.KindDescription = getDescription(jobItem.job_recruitment_type, 'name', lang);
    jobItem.StartDate = formatTime(Number(jobItem.create_time), 'YYYY-MM-DD');
    jobItem.EndDate = jobItem.job_expire_time
      ? formatTime(Number(jobItem.job_expire_time), 'YYYY-MM-DD')
      : copyright.uncertain_end_date_description;
    jobItem.WorkingPlace = getDescription(jobItem.address_list, '', lang);
    return (
      <div className={styles.socialRecruitmentDetailPage}>
        <Layout {...this.props} pageFixedSearch={true} copywriting={copywriting}>
          <SEO title={copyright.seo.title}
            keywords={copyright.seo.keywords}
            description={copyright.seo.description} />
          <div className={styles.RecruitmentDetail}>
            <div className={styles.contentBox}>
              <span className={styles.jobTitle}>{jobItem.title}</span>
              {
                jobItem.id
                  ? <div>
                    <div className={styles.jobBaseIntroPc}>
                      {
                        copyright.base_info.map((item) => (
                          <div key={item.id} className={styles.contentItem}>
                            <span className={styles.contentItemLeft}>{item.title}</span>
                            <span>{jobItem[item.key]}</span>
                          </div>
                        ))
                      }
                    </div>
                    <div className={styles.jobBaseIntroMobile}>
                      {
                        copyright.base_info.map((item) => {
                          if (item.notShowMobile) {
                            return null;
                          }
                          return (
                            <div key={item.id} className={styles.contentItem}>
                              <span>{jobItem[item.key]}</span>
                            </div>
                          );
                        })
                      }
                    </div>
                    <div>
                      <div className={styles.detailedInstructions}>
                        <span className={styles.detailedInstructionsTitle}>
                          {copyright.responsibility}
                        </span>
                        <pre className={styles.preClass}
                          dangerouslySetInnerHTML={{ __html: jobItem.description }}>
                        </pre>
                      </div>

                      <div className={styles.detailedInstructions}>
                        <span className={styles.detailedInstructionsTitle}>
                          {copyright.quantification}</span>
                        <pre className={styles.preClass}
                          dangerouslySetInnerHTML={{ __html: jobItem.requirement }}>
                        </pre>
                      </div>
                    </div>

                    <div className={styles.button}
                      onClick={this.applyJob.bind(this, lang)}>{copyright.apply_button_description}</div>
                  </div>
                  : <NoDataPlaceholder></NoDataPlaceholder>
              }
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    jobadCategory: state.app.jobadCategory,
  }),
  (dispatch) => ({
    saveJobAdCategory: (open) => dispatch(saveJobAdCategory(open)),
  }),
)(RecruitmentDetail);

export const query = graphql`
query detailQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
      multiple_description
    }
    config {
      websites {
        key
        id
        description
      }
    }
    jobDetails {
      apply_button_description
      quantification
      responsibility
      uncertain_salary_description
      uncertain_end_date_description
      no_data_description
      base_info {
        id
        key
        notShowMobile
        title
      }
      seo {
        description
        keywords
        title
      }
    }

  }
}
`;
